<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-row class="match-height">
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="warning"
          icon="UsersIcon"
          :statistic="memberOnline"
          :statistic-title="$t('Member')"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="primary"
          icon="UsersIcon"
          :statistic="franchiseOnline"
          :statistic-title="$t('Admin')"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          color="success"
          icon="UsersIcon"
          :statistic="rootOnline"
          :statistic-title="$t('Root')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import store from '@/store'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

import {
  BCol,
  BRow,
  // BCard,
  // BCardHeader,
  // BCardTitle,
  // BDropdown,
  // BDropdownItem,
  // BCardBody,
} from 'bootstrap-vue'
// import VueApexCharts from 'vue-apexcharts'
// import { $themeColors } from '@themeConfig'

// const STORE_MODULE_NAME = 'dashboard'

export default {
  components: {
    BCol,
    BRow,
    StatisticCardVertical,
    // BCard,
    // BCardHeader,
    // BCardTitle,
    // BDropdown,
    // BDropdownItem,
    // BCardBody,
    // VueApexCharts,
  },
  data() {
    return {
      memberOnline: 0,
      franchiseOnline: 0,
      rootOnline: 0,
    }
  },
  computed: {
    // wsClient() {
    //   return store.state.websockets.wsClient[STORE_MODULE_NAME]
    // },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    // eslint-disable-next-line no-unused-vars
    const Id = userData.userId

    // WebSocket Connect
    // const pathname = `/Dashboard?type=franchise&userId=${Id}`
    // const params = {
    //   pathname,
    //   page: STORE_MODULE_NAME,
    // }
    // store.dispatch('websockets/connect', params)
  },
  // destroyed() {
  // WebSocket Disconnect
  // store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
  // },
  // beforeMount() {
  //   // WebSocket
  //   if (this.wsClient) {
  //     // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
  //     this.wsClient.onerror = err => {
  //       console.log('onError')
  //       console.log(err)
  //     }
  //     this.wsClient.onclose = err => {
  //       console.log('onClose')
  //       console.log(err)
  //     }
  //     this.wsClient.onmessage = msg => {
  //       const SocketMessage = JSON.parse(msg.data)
  //       
  //       console.log(SocketMessage)

  //       this.memberOnline = SocketMessage.data.mobile.member.length
  //       this.franchiseOnline = SocketMessage.data.web.franchise.length
  //       this.rootOnline = SocketMessage.data.web.root.length
  //     }
  //   }
  // },
}
</script>

<style></style>
